<template>
  <v-container class="container-style">
    <div style="display: flex; justify-content: space-between">
      <h1 style="font-size: 28px; font-weight: 600">
        {{ !$language ? "SMS & Email" : "এসএমএস ও ইমেইল" }}
      </h1>
      <v-menu style="position: relative; margin-left: 30px" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            depressed
            large
            v-bind="attrs"
            class="btn-create-style"
            v-on="on"
            ><v-icon
              :size="createIconsSize"
              :class="{ 'mr-3': screenSize > 1600 }"
              >add</v-icon
            >
            <span v-if="screenSize >= 600" class="create-text">{{
              !$language ? "Create" : "পাঠান"
            }}</span>
            <v-icon
              :size="createIconsSize"
              :class="{ 'ml-2': screenSize > 1600 }"
              >keyboard_arrow_down</v-icon
            >
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="
              $router.push({
                name: 'create-email',
                params: { elementType: 'email' },
              })
            "
          >
            <img
              class="create-img"
              src="@ecf/global/assets/img/icons/email_icon_grey.svg"
            />
            <v-list-item-title class="clickable"
              ><span class="create-bulk-text">{{
                !$language ? "Bulk Email" : "বাল্ক ইমেইল"
              }}</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="
              $router.push({
                name: 'create-sms',
                params: { elementType: 'sms' },
              })
            "
          >
            <img
              class="create-img"
              src="@ecf/global/assets/img/icons/sms_icon_grey.svg"
            />
            <v-list-item-title class="clickable"
              ><span class="create-bulk-text">{{
                !$language ? "Bulk SMS" : "বাল্ক এসএমএস"
              }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-row class="mt-4 d-flex align-center" no-gutters>
      <span
        v-for="(tabName, i) in filteredTabNames"
        :key="i"
        class="pa-0"
        style="margin-right: 2%"
      >
        <v-btn
          v-if="tab === i"
          elevation="0"
          color="#0099dc"
          class="white--text tab-button-style"
          style="text-transform: uppercase !important"
          >{{ tabName }}</v-btn
        >
        <v-btn
          v-else
          text
          style="white-space: nowrap; text-transform: uppercase !important"
          class="tab-button-style"
          @click="tab = i"
          >{{ tabName }}</v-btn
        >
      </span>
      <span class="d-flex justify-center align-center" style="margin-left: 1%">
        <v-menu offset-y left>
          <template v-slot:activator="{ on: { click }, attrs }">
            <div class="clickable" v-bind="attrs" @click.stop.prevent="click">
              <img
                :class="[
                  tab === 0 ? '' : 'filter-primary',
                  'tab-filter-button-style',
                ]"
                src="@ecf/global/assets/img/icons/filter_icon.svg"
              />
            </div>
          </template>
          <v-list class="lg:text-xs xl:text-sm">
            <v-list-item @click="applyFilter(0)">
              <v-icon size="24">mdi-message</v-icon>
              <v-list-item-title class="clickable"
                ><span class="create-bulk-text">{{
                  !$language ? "All" : "সকল"
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="applyFilter(2)">
              <img
                size="20"
                src="@ecf/global/assets/img/icons/sms_icon_grey.svg"
              />
              <v-list-item-title class="clickable"
                ><span class="create-bulk-text">{{
                  !$language ? "SMS" : "এসএমএস"
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="applyFilter(1)">
              <img
                size="20"
                src="@ecf/global/assets/img/icons/email_icon_grey.svg"
              />
              <v-list-item-title class="clickable"
                ><span class="create-bulk-text">{{
                  !$language ? "Email" : "ইমেইল"
                }}</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <v-col
        :class="['d-flex align-center', { 'justify-end': screenSize >= 600 }]"
        order="1"
      >
        <div
          :class="[
            'd-flex border-1-2 border-solid border-primary mb-3',
            { 'mt-3': screenSize < 900 },
          ]"
          style="border-radius: 6px; background-color: #ebf2ff"
        >
          <div class="orange white--text text-center px-5 py-2 text-truncate">
            <p
              class="ft-avenir ft-light text-truncate balance-text"
              style="font-weight: bold; margin-bottom: 0px"
            >
              {{ !$language ? "Remaining SMS" : "অবশিষ্ট এসএমএস" }}
            </p>
          </div>
          <div
            class="flex-grow-1 align-center ft-avenir orange--text py-2 px-3 balance-text"
            style="letter-spacing: 2px; font-weight: bold"
          >
            {{ numerOfSMS }}
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="!renderNow['status']">
      <v-row justify="center" align="center" align-content="center">
        <v-col cols="4">
          <v-progress-circular
            size="60"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <no-content-component
      v-else-if="filteredDate?.length === 0"
      :message="noContentMessage"
    ></no-content-component>
    <div v-else class="card-layout">
      <mk-grid-item-layout :cols="layout.cols" :gap="layout.gap">
        <div v-for="(email, index) in filteredDate" :key="index">
          <sms-or-email-card :email-object="email"></sms-or-email-card>
        </div>
      </mk-grid-item-layout>
    </div>
  </v-container>
</template>

<script>
import SmsOrEmailCard from "#ecf/sms-and-email/SmsOrEmailCard";
import ContactNotificationService from "#ecf/sms-and-email/services/ContactNotificationService.js";
import MkGridItemLayout from "@ecf/global/components/MkGridItemLayout";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "SmsAndEmail",
  components: { SmsOrEmailCard, MkGridItemLayout, NoContentComponent },
  data() {
    return {
      renderNow: {},
      networkError: false,
      historyResponse: {},
      tabNames: ["All", "Today", "This Week", "This Month", "This Year"],
      tab: 0,
      courses: [
        "Bangla",
        "English",
        "Mathematics",
        "Physics",
        "Chemistry",
        "Biology",
      ],
      emails: [],
      filter: 0, //is all
      numerOfSMS: 0,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    createIconsSize() {
      let s = this.screenSize;
      return s < 600 ? 20 : s < 900 ? 24 : s < 1601 ? 30 : 36;
    },
    layout() {
      let s = this.screenSize;
      return {
        cols: s < 900 ? 1 : s < 1920 ? 2 : 3,
        gap: s < 900 ? `10px` : s < 1920 ? `30px` : `20px`,
      };
    },
    filteredDate() {
      let today = new Date();
      let one_day = 1000 * 60 * 60 * 24;
      return this.emails.filter((item) => {
        let sent_date = new Date(item.date_time.slice(0, 10));
        let difference = Math.ceil(
          Math.abs(today - sent_date) / one_day
        ).toFixed(0);
        if (
          this.tab == 1 &&
          today.toDateString() === sent_date.toDateString()
        ) {
          if (this.filter == 0) {
            return true;
          } else {
            if (this.filter == 1 && item.message_type == "email") return true;
            else if (this.filter == 2 && item.message_type == "sms")
              return true;
            return false;
          }
        } else if (this.tab == 2 && difference < 8) {
          if (this.filter == 0) {
            return true;
          } else {
            if (this.filter == 1 && item.message_type == "email") return true;
            else if (this.filter == 2 && item.message_type == "sms")
              return true;
            return false;
          }
        } else if (this.tab == 3 && difference < 31) {
          if (this.filter == 0) {
            return true;
          } else {
            if (this.filter == 1 && item.message_type == "email") return true;
            else if (this.filter == 2 && item.message_type == "sms")
              return true;
            return false;
          }
        } else if (this.tab == 4 && difference < 366) {
          if (this.filter == 0) {
            return true;
          } else {
            if (this.filter == 1 && item.message_type == "email") return true;
            else if (this.filter == 2 && item.message_type == "sms")
              return true;
            return false;
          }
        } else if (this.tab == 0) {
          if (this.filter == 0) {
            return true;
          } else {
            if (this.filter == 1 && item.message_type == "email") return true;
            else if (this.filter == 2 && item.message_type == "sms")
              return true;
            return false;
          }
        }
      });
    },
    filteredTabNames() {
      let s = this.screenSize;
      return this.tabNames.filter((e) => {
        if (e == "This Week" && s < 600) return false;
        else return true;
      });
    },
    contentType() {
      return this.filter == 0
        ? this.$language
          ? "ইমেইল/এসএমএস"
          : "emails/messages"
        : this.filter == 1
        ? this.$language
          ? "ইমেইল"
          : "emails"
        : this.$language
        ? "এসএমএস"
        : "messages";
    },
    filterType() {
      switch (this.tab) {
        case 1:
          return this.$language ? " আজ" : " today";
        case 2:
          return this.$language ? " এই সপ্তাহে" : " this week";
        case 3:
          return this.$language ? " এই মাসে" : " this month";
        case 4:
          return this.$language ? " এই বছরে" : " this year";
        default:
          return "";
      }
    },
    noContentMessage() {
      if (this.networkError) {
        return this.$language
          ? "বর্তমানে ইমেল/এসেমেস সম্পর্কিত তথ্য এনে দেয়া সম্ভব হচ্ছে না"
          : "Currently SMS/Email data is unvailable";
      }
      return this.$language
        ? `আপনি${this.filterType} কোনো ${this.contentType} পাঠান নি`
        : `You have not sent any ${this.contentType}${this.filterType}`;
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.$set(this.renderNow, "status", true);
      this.networkError = true;
      return;
    }
    try {
      this.$set(this.renderNow, "status", false);
      this.historyResponse = await ContactNotificationService.retrieveHistory(
        this.$store.state.user.user.last_partner_code
      );
      for (
        let len = this.historyResponse.messages.length, i = 0;
        i < len;
        i += 1
      ) {
        this.emails.push(this.historyResponse.messages[i]);
      }
      this.numerOfSMS = String(Number(this.historyResponse.balance)); // this.historyResponse.balance will be replaced with num_of_sms
      this.$set(this.renderNow, "status", true);
    } catch (e) {
      this.$root.$emit("alert", ["Alert", e.message]);
      this.$set(this.renderNow, "status", false);
    }
    // this.fakeEmailObjects();
  },
  methods: {
    applyFilter(target) {
      this.filter = target;
    },
    fakeEmailObjects() {
      let n = Math.ceil(Math.random() * 23) + 3;
      for (let i = 0; i < n; i++) {
        let m = Math.ceil(Math.random() * 100);
        this.emails.push({
          type: m > 50 ? "email" : "sms",
          student: m > 0 && m < 65 ? true : false,
          guardian: m > 35 && m < 100 ? true : false,
          total: 2 * m,
          course: this.courses.slice(0, (m % 4) + 1),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  @media screen and (min-width: 1601px) {
    padding-top: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    padding-top: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 599px) {
    padding-top: 10px;
  }
}

.btn-create-style {
  min-width: 30px !important;
  min-height: 30px !important;
  border-radius: 6px;
  @media screen and (min-width: 1601px) {
    height: 50px;
    width: 170px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    height: 40px;
    width: 120px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    height: 40px;
    width: 100px;
  }

  @media screen and (max-width: 599px) {
    height: 35px !important;
    width: 70px;
  }
}

.create-text {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.create-img {
  @media screen and (min-width: 1601px) {
    height: 24px;
    widht: 24px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    height: 20px;
    widht: 20px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    height: 18px;
    widht: 18px;
  }

  @media screen and (max-width: 599px) {
    height: 18px;
    widht: 18px;
  }
}

.create-bulk-text {
  @media screen and (min-width: 1601px) {
    font-size: 14px;
    padding-left: 25px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 12px;
    padding-left: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 11px;
    padding-left: 8px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
    padding-left: 5px;
  }
}

.card-layout {
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (min-width: 1601px) {
    margin-top: 55px;
    height: 800px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    margin-top: 40px;
    height: 520px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    margin-top: 10px;
    height: 1000px;
  }

  @media screen and (max-width: 599px) {
    margin-top: 10px;
    height: 440px;
  }
}

.tab-button-style {
  min-width: 10px !important;
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
    padding: 8px !important;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
    padding: 6px !important;
    height: 20px !important;
  }
}

.tab-filter-button-style {
  height: 24px;
  width: 24px;

  @media screen and (max-width: 599px) {
    height: 16px;
    width: 16px;
  }
}

.filter-primary {
  filter: invert(38%) sepia(72%) saturate(1310%) hue-rotate(169deg)
    brightness(99%) contrast(101%);
}

.v-slide-group__content.v-tabs-bar__content {
  background-color: #00c853 !important;
  color: #00c853 !important;
}

.v-item-group.theme--light.v-slide-group.v-slide-group--is-overflowing.v-tabs-bar.v-tabs-bar--is-mobile.primary--text {
  background-color: #00c853 !important;
  color: #00c853 !important;
}

.balance-text {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}
</style>
