<template>
  <div :style="gridStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MkGridItemLayout",
  props: {
    cols: {
      type: Number,
      default: 3
    },
    gap: {
      type: String,
      default: "10px"
    }
  },
  data() {
    return {};
  },
  computed: {
    gridStyle() {
      let styleStr = `display: grid; grid-template-columns: repeat(${this.cols}, 1fr); gap: ${this.gap}`;
      return styleStr;
    }
  }
};
</script>

<style scoped lang="scss"></style>
