<template>
  <v-card flat class="card-style" :class="{ 'box-size': screenSize <= 600 }">
    <v-row style="height: 100%" no-gutters>
      <v-col
        :cols="screenSize <= 400 ? 5 : 4"
        class="d-flex d-xs-flex justify-center align-center"
        :class="{ 'pa-0': screenSize <= 1200, 'py-0': screenSize > 1200 }"
      >
        <v-card
          :class="{
            'py-7 pl-8 pr-3 block': screenSize > 600,
            'py-6 pl-4 pr-1 block': screenSize <= 600
          }"
          style="border-radius: 6px 0px 0px 6px"
          flat
          color="#EBF2FF"
        >
          <v-row
            class="d-flex justify-start align-start"
            style="display: flex; height: 25%"
            :style="
              `color: ${
                emailObject.message_type === 'email' ? '#F44336' : '#25AE88'
              }; font-size: ${screenSize <= 600 ? 14 : 20}px`
            "
          >
            <!--          <v-icon style="color: inherit;">{{ card_type === 'email' ? `mail`:`message`}}</v-icon>-->
            <img class="icon-size" :src="smsOrEmailIcon" />
            <span class="ml-3 d-flex">{{
              emailObject.message_type.toUpperCase()
            }}</span>
          </v-row>
          <v-row v-if="screenSize > 600" style="height: 25%"></v-row>
          <v-row
            :style="
              `font-size: ${screenSize <= 600 ? 10 : 13}px;
              display: flex;
              justify-content: start;
              height: ${screenSize <= 600 ? 35 : 10}%;`
            "
            :class="{ 'box-size': screenSize <= 600 }"
            ><span :class="screenSize <= 600 ? 'total-textbox' : ''"
              >Total Sent {{ elementTypeCased }}(s):</span
            ></v-row
          >
          <v-row
            :no-gutters="screenSize <= 600"
            :style="
              `font-weight: 500; font-size: ${screenSize <= 600 ? 16 : 20}px`
            "
            :class="['d-flex', screenSize > 600 ? 'align-end' : 'align-center']"
          >
            {{ totalSent }}
          </v-row>
        </v-card>
      </v-col>
      <v-col :cols="screenSize <= 400 ? 7 : 8" class="ma-0">
        <div
          :class="[
            {
              'px-2 py-3': screenSize <= 600,
              'px-8 py-7': screenSize > 600
            },
            'block'
          ]"
          outlined
        >
          <v-row
            :no-gutters="screenSize <= 600"
            :style="`height: ${screenSize <= 600 ? 25 : 30}%`"
          >
            <div style="width: 100%">
              <span
                v-if="
                  typeof emailObject.recipients_info[0].course_name === 'string'
                "
                style="font-weight: 600; color: #707070"
                :class="screenSize > 600 ? 'course-name-lg' : 'course-name-sm'"
                >{{ emailObject.recipients_info[0].course_name }}</span
              >
              <span
                v-else
                style="font-weight: 600; color: #707070"
                :class="screenSize > 600 ? 'course-name-lg' : 'course-name-sm'"
                >{{ emailObject.recipients_info[0].course_name[0] }}</span
              >
              <span
                v-if="
                  typeof emailObject.recipients_info[0].course_name !== 'string'
                "
                style="font-size: 12px"
              >
                +{{
                  emailObject.recipients_info[0].course_name.length + 1
                }}
                more</span
              >
            </div>
            <div
              v-if="screenSize > 600"
              style="color: #707070"
              :class="screenSize > 600 ? 'date-size-bg' : 'date-size-sm'"
              class="mt-2"
            >
              Date Sent:
              {{
                new Date(this.emailObject.date_time.slice(0, 10)).toDateString()
              }}
            </div>
          </v-row>
          <v-row
            :no-gutters="screenSize <= 600"
            :style="`height: ${screenSize <= 600 ? 40 : 30}%`"
          >
            <v-col
              v-if="emailObject.is_send_to_guardian"
              :cols="screenSize <= 600 ? '12' : '6'"
              class="pa-0 d-flex align-center"
            >
              <img
                :style="parentSendIcon.size"
                :src="parentSendIcon.link"
                class="svg-filter"
              />
              <span style="font-size: 14px; padding-left: 2%">Guardians</span>
            </v-col>
            <v-col
              v-if="emailObject.is_send_to_student"
              :cols="screenSize <= 600 ? '12' : '6'"
              class="pa-0 d-flex align-center"
            >
              <img
                :style="studentSendIcon.size"
                :src="studentSendIcon.link"
                class="svg-filter"
              />
              <span style="font-size: 14px; padding-left: 2%">Students</span>
            </v-col>
          </v-row>
          <v-row
            :no-gutters="screenSize <= 600"
            style="height: 30%"
            class="d-flex align-end justify-end"
          >
            <v-btn
              color="#0099dc"
              :outlined="screenSize > 600"
              :icon="screenSize <= 600"
              :class="{
                'btn-style-lg': screenSize > 1200,
                'btn-style-md': screenSize > 600 && screenSize <= 1200,
                'btn-style-sm': screenSize <= 600
              }"
              :to="
                `${
                  emailObject.message_type === 'email' ? 'email' : 'sms'
                }-details/${emailObject.message_id}`
              "
              >Details
            </v-btn>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { firstLetterUpperCase } from "@ecf/global/helpers";

export default {
  name: "SmsOrEmailCard",
  props: ["emailObject"],
  data() {
    return {};
  },
  computed: {
    totalSent() {
      if (
        this.emailObject.is_send_to_guardian &&
        this.emailObject.is_send_to_student
      ) {
        let count = this.emailObject.recipients_info.length;
        for (let item of this.emailObject.recipients_info) {
          count += item.guardian_info.length;
        }
        return count;
      } else if (
        this.emailObject.is_send_to_guardian &&
        !this.emailObject.is_send_to_student
      ) {
        let count = 0;
        for (let item of this.emailObject.recipients_info) {
          count += item.guardian_info.length;
        }
        return count;
      } else if (
        !this.emailObject.is_send_to_guardian &&
        this.emailObject.is_send_to_student
      ) {
        let count = this.emailObject.recipients_info.length;
        return count;
      } else {
        return 0;
      }
    },
    smsOrEmailIcon() {
      return this.emailObject.message_type === "email"
        ? require("@ecf/global/assets/img/icons/email_icon.svg")
        : require("@ecf/global/assets/img/icons/sms_icon.svg");
    },
    elementTypeCased() {
      return this.emailObject.message_type === "email"
        ? firstLetterUpperCase(this.emailObject.message_type)
        : this.emailObject.message_type.toUpperCase();
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    parentSendIcon() {
      return {
        link: require("#ecf/sms-and-email/assests/account-tie-outline.svg"),
        size: `height: 20px`
      };
    },
    studentSendIcon() {
      return {
        link: require("#ecf/sms-and-email/assests/account-school-outline.svg"),
        size: `height: 20px`
      };
    }
  }
};
</script>

<style scoped lang="scss">
.block {
  height: 100%;
  width: 100%;
}

.card-style {
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  height: 180px;
  @media screen and (min-width: 1601px) {
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
  }

  @media screen and (max-width: 599px) {
    height: 140px;
  }
}

.box-size {
  font-size: 14px;
  padding: 0%;
  margin: 0%;
}

.box-size-md {
  font-size: 14px;
}

.button-style {
  width: 10%;
}

.btn-style-lg {
  height: 45px;
  width: 125px;
  font-size: 18px;
  font-weight: bold;
  padding-top: 2px;
}

.btn-style-md {
  height: 35px;
  width: 85px;
  font-size: 14px;
  font-weight: bold;
  padding-top: 0px;
  // margin-left: 2%;
}

.btn-style-sm {
  height: 35px;
  width: 85px;
  font-size: 14px;
  font-weight: bold;
  padding-top: 0px;
  // margin-left: 20%;
}

.course-name-lg {
  font-size: 16px;
}

.course-name-sm {
  font-size: 14px;
}

.date-size-bg {
  font-size: 14px;
}

.date-size-sm {
  font-size: 12px;
}

.total-textbox {
  font-size: 12px;
}

.icon-size {
  width: 32px;
  height: 32px;
  @media screen and (min-width: 1601px) {
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
  }

  @media screen and (max-width: 599px) {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
}

.svg-filter {
  filter: invert(72%) sepia(83%) saturate(6302%) hue-rotate(171deg)
    brightness(96%) contrast(102%);
}
</style>
