var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-style",
      class: { "box-size": _vm.screenSize <= 600 },
      attrs: { flat: "" },
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex d-xs-flex justify-center align-center",
              class: {
                "pa-0": _vm.screenSize <= 1200,
                "py-0": _vm.screenSize > 1200,
              },
              attrs: { cols: _vm.screenSize <= 400 ? 5 : 4 },
            },
            [
              _c(
                "v-card",
                {
                  class: {
                    "py-7 pl-8 pr-3 block": _vm.screenSize > 600,
                    "py-6 pl-4 pr-1 block": _vm.screenSize <= 600,
                  },
                  staticStyle: { "border-radius": "6px 0px 0px 6px" },
                  attrs: { flat: "", color: "#EBF2FF" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-start align-start",
                      staticStyle: { display: "flex", height: "25%" },
                      style: `color: ${
                        _vm.emailObject.message_type === "email"
                          ? "#F44336"
                          : "#25AE88"
                      }; font-size: ${_vm.screenSize <= 600 ? 14 : 20}px`,
                    },
                    [
                      _c("img", {
                        staticClass: "icon-size",
                        attrs: { src: _vm.smsOrEmailIcon },
                      }),
                      _c("span", { staticClass: "ml-3 d-flex" }, [
                        _vm._v(
                          _vm._s(_vm.emailObject.message_type.toUpperCase())
                        ),
                      ]),
                    ]
                  ),
                  _vm.screenSize > 600
                    ? _c("v-row", { staticStyle: { height: "25%" } })
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      class: { "box-size": _vm.screenSize <= 600 },
                      style: `font-size: ${_vm.screenSize <= 600 ? 10 : 13}px;
            display: flex;
            justify-content: start;
            height: ${_vm.screenSize <= 600 ? 35 : 10}%;`,
                    },
                    [
                      _c(
                        "span",
                        { class: _vm.screenSize <= 600 ? "total-textbox" : "" },
                        [
                          _vm._v(
                            "Total Sent " +
                              _vm._s(_vm.elementTypeCased) +
                              "(s):"
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      class: [
                        "d-flex",
                        _vm.screenSize > 600 ? "align-end" : "align-center",
                      ],
                      style: `font-weight: 500; font-size: ${
                        _vm.screenSize <= 600 ? 16 : 20
                      }px`,
                      attrs: { "no-gutters": _vm.screenSize <= 600 },
                    },
                    [_vm._v(" " + _vm._s(_vm.totalSent) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "ma-0",
              attrs: { cols: _vm.screenSize <= 400 ? 7 : 8 },
            },
            [
              _c(
                "div",
                {
                  class: [
                    {
                      "px-2 py-3": _vm.screenSize <= 600,
                      "px-8 py-7": _vm.screenSize > 600,
                    },
                    "block",
                  ],
                  attrs: { outlined: "" },
                },
                [
                  _c(
                    "v-row",
                    {
                      style: `height: ${_vm.screenSize <= 600 ? 25 : 30}%`,
                      attrs: { "no-gutters": _vm.screenSize <= 600 },
                    },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        typeof _vm.emailObject.recipients_info[0]
                          .course_name === "string"
                          ? _c(
                              "span",
                              {
                                class:
                                  _vm.screenSize > 600
                                    ? "course-name-lg"
                                    : "course-name-sm",
                                staticStyle: {
                                  "font-weight": "600",
                                  color: "#707070",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.emailObject.recipients_info[0]
                                      .course_name
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                class:
                                  _vm.screenSize > 600
                                    ? "course-name-lg"
                                    : "course-name-sm",
                                staticStyle: {
                                  "font-weight": "600",
                                  color: "#707070",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.emailObject.recipients_info[0]
                                      .course_name[0]
                                  )
                                ),
                              ]
                            ),
                        typeof _vm.emailObject.recipients_info[0]
                          .course_name !== "string"
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [
                                _vm._v(
                                  " +" +
                                    _vm._s(
                                      _vm.emailObject.recipients_info[0]
                                        .course_name.length + 1
                                    ) +
                                    " more"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.screenSize > 600
                        ? _c(
                            "div",
                            {
                              staticClass: "mt-2",
                              class:
                                _vm.screenSize > 600
                                  ? "date-size-bg"
                                  : "date-size-sm",
                              staticStyle: { color: "#707070" },
                            },
                            [
                              _vm._v(
                                " Date Sent: " +
                                  _vm._s(
                                    new Date(
                                      this.emailObject.date_time.slice(0, 10)
                                    ).toDateString()
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      style: `height: ${_vm.screenSize <= 600 ? 40 : 30}%`,
                      attrs: { "no-gutters": _vm.screenSize <= 600 },
                    },
                    [
                      _vm.emailObject.is_send_to_guardian
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0 d-flex align-center",
                              attrs: {
                                cols: _vm.screenSize <= 600 ? "12" : "6",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "svg-filter",
                                style: _vm.parentSendIcon.size,
                                attrs: { src: _vm.parentSendIcon.link },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "padding-left": "2%",
                                  },
                                },
                                [_vm._v("Guardians")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.emailObject.is_send_to_student
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0 d-flex align-center",
                              attrs: {
                                cols: _vm.screenSize <= 600 ? "12" : "6",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "svg-filter",
                                style: _vm.studentSendIcon.size,
                                attrs: { src: _vm.studentSendIcon.link },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "padding-left": "2%",
                                  },
                                },
                                [_vm._v("Students")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex align-end justify-end",
                      staticStyle: { height: "30%" },
                      attrs: { "no-gutters": _vm.screenSize <= 600 },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          class: {
                            "btn-style-lg": _vm.screenSize > 1200,
                            "btn-style-md":
                              _vm.screenSize > 600 && _vm.screenSize <= 1200,
                            "btn-style-sm": _vm.screenSize <= 600,
                          },
                          attrs: {
                            color: "#0099dc",
                            outlined: _vm.screenSize > 600,
                            icon: _vm.screenSize <= 600,
                            to: `${
                              _vm.emailObject.message_type === "email"
                                ? "email"
                                : "sms"
                            }-details/${_vm.emailObject.message_id}`,
                          },
                        },
                        [_vm._v("Details ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }