import { Backend } from "~/utils/backend";
import { checkStatus } from "/global/utils/helpers";

const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);

export default {
  async retrieveHistory(partner_code, opts = {}) {
    return $backend
      .post(
        "teacher_get_sms_email",
        { partner_code },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(r => r.data.content);
  },

  async retriveDetails(partner_code, message_id, opts = {}) {
    return $backend
      .post(
        "teacher_get_sms_email_details",
        { partner_code, message_id },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(r => r.data.content.message);
  },

  async sendBulk(partner_code, data, opts = {}) {
    let outgoing = { partner_code, ...data };
    return $backend
      .post("teacher_send_sms_email", outgoing, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus);
  }
};
