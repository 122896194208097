var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "container-style" },
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "h1",
            { staticStyle: { "font-size": "28px", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(!_vm.$language ? "SMS & Email" : "এসএমএস ও ইমেইল") +
                  " "
              ),
            ]
          ),
          _c(
            "v-menu",
            {
              staticStyle: { position: "relative", "margin-left": "30px" },
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "btn-create-style",
                              attrs: {
                                color: "primary",
                                depressed: "",
                                large: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            {
                              class: { "mr-3": _vm.screenSize > 1600 },
                              attrs: { size: _vm.createIconsSize },
                            },
                            [_vm._v("add")]
                          ),
                          _vm.screenSize >= 600
                            ? _c("span", { staticClass: "create-text" }, [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Create" : "পাঠান")
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "v-icon",
                            {
                              class: { "ml-2": _vm.screenSize > 1600 },
                              attrs: { size: _vm.createIconsSize },
                            },
                            [_vm._v("keyboard_arrow_down")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "create-email",
                            params: { elementType: "email" },
                          })
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "create-img",
                        attrs: {
                          src: require("@ecf/global/assets/img/icons/email_icon_grey.svg"),
                        },
                      }),
                      _c("v-list-item-title", { staticClass: "clickable" }, [
                        _c("span", { staticClass: "create-bulk-text" }, [
                          _vm._v(
                            _vm._s(
                              !_vm.$language ? "Bulk Email" : "বাল্ক ইমেইল"
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "create-sms",
                            params: { elementType: "sms" },
                          })
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "create-img",
                        attrs: {
                          src: require("@ecf/global/assets/img/icons/sms_icon_grey.svg"),
                        },
                      }),
                      _c("v-list-item-title", { staticClass: "clickable" }, [
                        _c("span", { staticClass: "create-bulk-text" }, [
                          _vm._v(
                            _vm._s(!_vm.$language ? "Bulk SMS" : "বাল্ক এসএমএস")
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mt-4 d-flex align-center",
          attrs: { "no-gutters": "" },
        },
        [
          _vm._l(_vm.filteredTabNames, function (tabName, i) {
            return _c(
              "span",
              {
                key: i,
                staticClass: "pa-0",
                staticStyle: { "margin-right": "2%" },
              },
              [
                _vm.tab === i
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "white--text tab-button-style",
                        staticStyle: {
                          "text-transform": "uppercase !important",
                        },
                        attrs: { elevation: "0", color: "#0099dc" },
                      },
                      [_vm._v(_vm._s(tabName))]
                    )
                  : _c(
                      "v-btn",
                      {
                        staticClass: "tab-button-style",
                        staticStyle: {
                          "white-space": "nowrap",
                          "text-transform": "uppercase !important",
                        },
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            _vm.tab = i
                          },
                        },
                      },
                      [_vm._v(_vm._s(tabName))]
                    ),
              ],
              1
            )
          }),
          _c(
            "span",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { "margin-left": "1%" },
            },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on: { click }, attrs }) {
                        return [
                          _c(
                            "div",
                            _vm._b(
                              {
                                staticClass: "clickable",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return click.apply(null, arguments)
                                  },
                                },
                              },
                              "div",
                              attrs,
                              false
                            ),
                            [
                              _c("img", {
                                class: [
                                  _vm.tab === 0 ? "" : "filter-primary",
                                  "tab-filter-button-style",
                                ],
                                attrs: {
                                  src: require("@ecf/global/assets/img/icons/filter_icon.svg"),
                                },
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "lg:text-xs xl:text-sm" },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.applyFilter(0)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "24" } }, [
                            _vm._v("mdi-message"),
                          ]),
                          _c(
                            "v-list-item-title",
                            { staticClass: "clickable" },
                            [
                              _c("span", { staticClass: "create-bulk-text" }, [
                                _vm._v(_vm._s(!_vm.$language ? "All" : "সকল")),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.applyFilter(2)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              size: "20",
                              src: require("@ecf/global/assets/img/icons/sms_icon_grey.svg"),
                            },
                          }),
                          _c(
                            "v-list-item-title",
                            { staticClass: "clickable" },
                            [
                              _c("span", { staticClass: "create-bulk-text" }, [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "SMS" : "এসএমএস")
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.applyFilter(1)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              size: "20",
                              src: require("@ecf/global/assets/img/icons/email_icon_grey.svg"),
                            },
                          }),
                          _c(
                            "v-list-item-title",
                            { staticClass: "clickable" },
                            [
                              _c("span", { staticClass: "create-bulk-text" }, [
                                _vm._v(
                                  _vm._s(!_vm.$language ? "Email" : "ইমেইল")
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: [
                "d-flex align-center",
                { "justify-end": _vm.screenSize >= 600 },
              ],
              attrs: { order: "1" },
            },
            [
              _c(
                "div",
                {
                  class: [
                    "d-flex border-1-2 border-solid border-primary mb-3",
                    { "mt-3": _vm.screenSize < 900 },
                  ],
                  staticStyle: {
                    "border-radius": "6px",
                    "background-color": "#ebf2ff",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "orange white--text text-center px-5 py-2 text-truncate",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "ft-avenir ft-light text-truncate balance-text",
                          staticStyle: {
                            "font-weight": "bold",
                            "margin-bottom": "0px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.$language
                                  ? "Remaining SMS"
                                  : "অবশিষ্ট এসএমএস"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 align-center ft-avenir orange--text py-2 px-3 balance-text",
                      staticStyle: {
                        "letter-spacing": "2px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.numerOfSMS) + " ")]
                  ),
                ]
              ),
            ]
          ),
        ],
        2
      ),
      !_vm.renderNow["status"]
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: "center",
                    align: "center",
                    "align-content": "center",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: "60",
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.filteredDate?.length === 0
        ? _c("no-content-component", {
            attrs: { message: _vm.noContentMessage },
          })
        : _c(
            "div",
            { staticClass: "card-layout" },
            [
              _c(
                "mk-grid-item-layout",
                { attrs: { cols: _vm.layout.cols, gap: _vm.layout.gap } },
                _vm._l(_vm.filteredDate, function (email, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("sms-or-email-card", {
                        attrs: { "email-object": email },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }